@font-face {
  font-family: 'Clarika Grotesque';
  font-style: normal;
  font-weight: 400;
  src:
    url('../font/Clarika-Grot-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Clarika Grotesque';
  font-style: normal;
  font-weight: 600;
  src:
    url('../font/Clarika-Grot-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Clarika Grotesque';
  font-weight: 800;
  font-style: normal;
  src:
    url('../font/Clarika-Grot-DemiBold.woff2') format('woff2');
}

// In previous versions, Material-UI has used LESS, then a custom inline-style solution to write the component styles, but these approaches have proven to be limited. A CSS-in-JS solution overcomes many of those limitations, and unlocks many great features (theme nesting, dynamic styles, self-support, etc.).
// For details see: https://material-ui.com/styles/basics/
// Why material-ui uses css-in-js instead of less/sass https://github.com/oliviertassinari/a-journey-toward-better-style

$primary-color: #009fff;
$font-color: #3a3a3a;
$background-color: #ffffff;
$font-family: 'clarika grotesque';

html {
  font-size: 16px;
  @media (min-height: 680px) {
    font-size: 18px;
    font-family: $font-family;
  }
}

.App {
  text-align: center;
}

.app-header {
  background-color: $background-color;
  height: 8vh;
  position: relative;
  font-size: calc(10px + 2vmin);
  color: $font-color;
}

*, *::before, *::after {
  box-sizing: inherit;
  z-index: 10;
}

